body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/* Fonts */
/* the font is used in svg for preview */
@font-face {
    font-family: 'FredokaOne-Regular';
    src: url('/assets/FredokaOne-Regular.ttf');
}

@font-face {
    font-family: 'TitilliumWeb-Regular';
    src: url('/assets/TitilliumWeb-Regular.ttf');
}

@font-face {
    font-family: 'TitilliumWeb-Bold';
    src: url('/assets/TitilliumWeb-Bold.ttf');
}

/* Buttons */
.waflee-btn {
    height: 48px;
    line-height: 48px;

    cursor: pointer;

    border-radius: 8px;

    font-family: 'TitilliumWeb-Bold';

    font-size: 16px;
    font-weight: bold;
    text-align: center;

    background-color: #fff;
    color: rgb(0, 72, 223);
}

.waflee-btn.disabled {
    opacity: 0.3;
    cursor: default;
}

.waflee-btn:not(.disabled):hover {
    background-color: rgba(0, 72, 223, 0.2);
}

.waflee-btn.primary-waflee-btn {
    background-color: #fa6400;
    color: #ffffff;
}

.waflee-btn.primary-waflee-btn:not(.disabled):hover {
    background-color: #ce4800;
}


/* waflee-util-btn */
.waflee-util-btn {
    font-family: 'TitilliumWeb-Regular';
    font-size: 16px;
    text-align: center;
    color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    padding-left: 9px;
    padding-right: 9px;
    height: 24px;
}

.waflee-util-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}

/* main background */
.main-background {
    background-image: radial-gradient(circle at 50% 0, #343c50, #1d2539 82%);
}


/* main pages - text styles */
.main-header {
    font-family: 'FredokaOne-Regular';
    color: #ffffff;
    text-align: center;

    margin-top: 48px;
    margin-bottom: 0;
}

.main-explanation {
    font-family: 'TitilliumWeb-Regular';
    opacity: 0.7;
    font-size: 16px;
    text-align: center;
    color: #ffffff;

    margin-top: 24px;
}