.how-it-works-car-img-container {
    height: 120px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
}

.how-it-works-car-img {
    height: 100%;
}

.how-it-works-car-text {
    margin-top: 32px;
    font-family: 'TitilliumWeb-Regular';
    font-size: 16px;
    text-align: center;
    color: #333333;
}

.how-it-works-dots-panel {
    margin-top: 28px;
    display: flex;
    justify-content: center;
}

.how-it-works-dot-container {
    width: 16px;
    height: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;;
}

.how-it-works-dot {
    width: 8px;
    height: 8px;
    background-color: #cccccc;
    border-radius: 4px;
}

.how-it-works-dot.active {
    background-color: #fa6400;
}

.how-it-works-buttons {
    display: flex;
    margin-top: 60px;
}

@media (min-width: 460px) {
    .how-it-works-buttons {
        margin-left: 24px;
        margin-right: 24px;
    }

    .how-it-works-buttons-bottom-gap {
        height: 24px;
    }
}

@media (max-width: 459px) {
    .how-it-works-buttons {
        margin-left: 16px;
        margin-right: 16px;
    }

    .how-it-works-buttons-bottom-gap {
        height: 16px;
    }
}

.hiw-btn {
    display: inline-block;
}

.hiw-prev-btn {
    width: 100px;
}

.hiw-next-btn {
    margin-left: 32px;
    flex-grow: 1;
}