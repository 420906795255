.form-container {
    margin-top: 48px;
}

.form-input {
    margin-top: 24px;

    border-radius: 6px;
    border: none;

    background-color: rgba(255, 255, 255, 0.3);
    color: rgb(255, 255, 255);

    display: block;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    padding: 12px 16px;

    font-family: 'TitilliumWeb-Regular';
    font-size: 16px;
}

.form-input.security-type-select {
    margin-top: 8px;
}

.security-type-select option {
    color: black;
}

.form-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.form-input {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
}

.util-buttons-container {
    margin-top: 24px;
    display: flex;
    align-items: center;
}

.extended-opts-arrow-icn {
    margin-right: 10px;
}

.extended-opts-arrow-icn.open {
    transform: rotate(180deg);
}

.extended-opts-container {
    margin-top: 24px;
}

.privacy-btn {
    margin-left: auto;
}

.generate-btn {
    margin-top: 24px;
    margin-bottom: 96px; /* gap between the content and the bottom of screen for feedback button */
}

.input-with-tooltip--container {
    position: relative;
}

.input-with-tooltip--input {
    padding-right: 48px;
}

.input-with-tooltip--tooltip-container {
    position: absolute;
    margin-top: -48px;
    height: 48px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    pointer-events: none;
}

.input-with-tooltip--tooltip-btn-container {
    position: absolute;
    width: 32px;
    height: 32px;
    display: flex;  
    justify-content: center;
    pointer-events: all;
}

.input-with-tooltip--tooltip {
    background-color: #fff;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    display: inline-flex;
    padding-left: 8px;
    padding-right: 8px;
    font-family: 'TitilliumWeb-Regular';
    font-size: 13px;
    color: #363636;
    align-items: center;
    z-index: 1;
    pointer-events: all;
}

