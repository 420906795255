@media (max-width: 619px) {
    .poster-prdct-qr svg {
        width: 100%;
        height: 100%;
    }
}
@media (min-width: 620px) {
    .poster-prdct-qr svg {
        width: 300px;
        height: 300px;
    }
}
