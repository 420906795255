
.root-container {
  width: 100%;
  height: 100%;
  position: fixed;
  
  overflow: auto;
}

.content-container {
  padding-left: 16px;
  padding-right: 16px;
  
  box-sizing: border-box;

  margin-left: auto;
  margin-right: auto;
}


@media (min-width: 492px) {
  .content-container {
    width: 492px;
  }
}
@media (max-width: 491px) {
  .content-container {
    width: 100%;
  }
}

@media (min-width: 492px) {
  .main-page-header {
    font-size: 40px;
  }
}

@media (max-width: 491px) {
  .main-page-header {
    font-size: 24px;
  }
}


.how-it-works-btn-container {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-pic-container {
  margin-top: 48px;

  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.main-pic {
  background-image: url('/assets/home.svg');
  
  width: 334px;
  height: 142px;
  display: inline-block;
}
